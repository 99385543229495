<template>
  <div>

    <b-row>
      <b-col>
        <h3>Circuito {{ id }}</h3>
      </b-col>
    </b-row>

    <app-collapse
      accordion
      type="margin"
    >
      <ProductCollapsableItem
        v-for="(product) in products"
        :key="product.IdProduct"
        :product="product"
        :external-pieces="productPieces(product.detailCircuit)"
        :origin-product-id="originProductId"
        :show-pieces-list="showPiecesList && $ability.can('read', 'Circuits_pieces')"
        :selectable-pieces-list="false"
        :set-pieces-query="true"
        :highlight-text="true"
      />
    </app-collapse>
  </div>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'

export default {
  components: {
    BCol,
    BRow,
    AppCollapse,
    ProductCollapsableItem: () => import('@/modules/production/products/components/ProductCollapsableItem.vue'),
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    products: {
      type: Array,
      required: true,
    },
    originProductId: {
      type: Number,
      default: 0,
    },
    showPiecesList: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    productPieces(details) {
      let pieces = []

      if (this.showPiecesList && details) {
        details.forEach(detail => {
          pieces = [...pieces, detail.piece]
        })
      }

      return pieces
    },
  },
}
</script>
